let request = document.querySelector('.request');
let ty = document.querySelector('.ty');
let servicesPopup = document.querySelectorAll('.services-popup');
let servicesList = document.querySelectorAll('.services-popup__list');

let teamPopup = document.querySelectorAll('.team-popup');

let servicesItem = document.querySelectorAll('.services__item');
let teamItem = document.querySelectorAll('.team__item');

let closeEl = document.querySelectorAll('.popup__close');

let modal = document.querySelectorAll('.modal');

let body = document.querySelector('body');

let primaryBtn = document.querySelectorAll('.btn-primary');
let requestBtn = document.querySelector('.request__btn');


$(document).ready(
  function () {
      $('.features__wrapper--desc').niceScroll({
          scrollspeed: 100,
          cursorcolor: "#E1E1E1",
          cursorwidth: "6px",
          railpadding: { top: 10, right: -10, left: 0, bottom: 0 },
      });

      AOS.init({
        offset: 10,
        duration: 400,
        delay: 0,
      });

      $("#your-phone").mask("+7 (999) 999-99-99");


      $(".request__btn").click(
        function(){
          if ($("#your-name").val() == "") {
            alert('Введите имя');
          } else if ($("#your-phone").val() == "") {
            alert('Введите телефон');
          } else if ($("#your-time").val() == "Выберите время") {
            alert('Выберите время');
          } else {
            sendAjaxForm('request__form', 'mail.php');
            return false; 
          }

        }
      );


      function sendAjaxForm(ajax_form, url) {
        $.ajax({
            url:     url,
            type:     "POST",
            dataType: "html",
            data: $("."+ajax_form).serialize(), 
            success: function(response) {
              modal.forEach(item => {
                item.classList.remove('show');
                body.classList.remove('modal-open');
              });

              ty.classList.add('show');
              body.classList.add('modal-open');
              $("."+ajax_form).trigger('reset');
          },
          error: function(response) {
              alert('Ошибка. Данные не отправлены.');
          }
       });
    }
  }
);


// const smoothLinks = document.querySelectorAll('.header__nav-link');

// if(smoothLinks) {
//   for (let smoothLink of smoothLinks) {
//       smoothLink.addEventListener('click', function (e) {
//           e.preventDefault();
//           const id = smoothLink.getAttribute('href');
  
//           document.querySelector(id).scrollIntoView({
//               behavior: 'smooth',
//               block: 'end'
//           });
//       });
//   };
// }

$('.smooth').on( 'click', function(e){ 
    e.preventDefault();
    var el = $(this);
    var dest = el.attr('href'); // получаем направление
    if(dest !== undefined && dest !== '') { // проверяем существование
        $('html').animate({ 
    	    scrollTop: $(dest).offset().top // прокручиваем страницу к требуемому элементу
        }, 500 // скорость прокрутки
        );
    }
    return false;
});


const featuresSliderMob = new Swiper('.features__wrapper--mob', {
  // Optional parameters
  direction: 'horizontal',
  slidesPerView: 'auto',

  scrollbar: {
      el: '.features-scrollbar',
  },
});

const teamSlideMob = new Swiper('.team__wrapper--mob', {
  // Optional parameters
  direction: 'horizontal',
  slidesPerView: 'auto',

  scrollbar: {
      el: '.team-scrollbar',
  },
});


const headerBurger = document.querySelector('.header__burger');
const header = document.querySelector('.header');
const headerNav = document.querySelector('.header__nav');
let headerLink = document.querySelectorAll('.header__nav-link');


if (headerBurger) {
  headerBurger.addEventListener('click', () => {
      header.classList.toggle('active');
      headerBurger.classList.toggle('active');
      headerNav.classList.toggle('active');
      body.classList.toggle('modal-open');

      if(headerNav.classList.contains('active')) {
          headerLink.forEach(link => {
              link.addEventListener('click', () => {
                  headerNav.classList.remove('active');
                  header.classList.remove('active');
                  headerBurger.classList.remove('active');
                  body.classList.remove('modal-open');
              });
          });
      }
  });
}


if(primaryBtn) {
  primaryBtn.forEach(btn => {
      btn.addEventListener('click', (e) => {
          e.preventDefault();
          modal.forEach(item => {
              item.classList.remove('show', 'block');
              body.classList.remove('modal-open');
          });
          request.classList.add('show');
          body.classList.add('modal-open');
      });
  });
}

// if(requestBtn) {
//   requestBtn.addEventListener('click', (e) => {
//       e.preventDefault();
//       modal.forEach(item => {
//           item.classList.remove('show');
//           body.classList.remove('modal-open');
//       });
//       ty.classList.add('show');
//       body.classList.add('modal-open');
//   });
// }

if(closeEl) {
  closeEl.forEach(item => {
      item.addEventListener('click', () => {
          modal.forEach(mod => {
            mod.classList.remove('show', 'block');
            body.classList.remove('modal-open');
          });
      });
  });
}


if(servicesItem) {
  servicesItem.forEach((item, i) => {
      item.addEventListener('click', () => {
          servicesPopup[i].classList.add('show');
          body.classList.add('modal-open');
          // $('.services-popup__list').getNiceScroll({
          //     scrollspeed: 100,
          //     cursorcolor: "#E1E1E1",
          //     cursorwidth: "6px",
          //     railpadding: { top: 10, right: 5, left: 0, bottom: 0 },
  
          // }).onResize();
      });
      
  });
}

if(teamItem) {
  teamItem.forEach((item, i) => {
      item.addEventListener('click', () => {
          teamPopup[i].classList.add('block');
          body.classList.add('modal-open');
      });
  });
}

if (modal) {
  modal.forEach(mod => {
      mod.addEventListener('click', (e) => {
          if (e.target.classList.contains('modal')) {
              mod.classList.remove('show', 'block');
              body.classList.remove('modal-open');
          }
      });
  });
}



const paralax = document.querySelector('.paralax');

if (paralax) {
  const content = document.querySelector('.paralax-container');
  const ship = document.querySelector('.paralax-ship');
  const island = document.querySelector('.paralax-island');
  const zub = document.querySelector('.paralax-zub');
  const zub2 = document.querySelector('.paralax-zub2');
  const ball = document.querySelector('.paralax-ball');
  const aboutImg = document.querySelector('.paralax-about');
  const servicesZub = document.querySelector('.paralax-services');
  const robot = document.querySelector('.paralax-robot');
  const footerShip = document.querySelector('.paralax-footer');

  const forShip = 15;
  const forIsland = 10;
  const forZub = 10;
  const forZub2 = 15;
  const forBall = 25;
  const forAboutImg = 35;
  const forServicesZub = 10;
  const forRobot = 55;
  const forFooterShip = 15;

  const paralaxSpeed = 0.05;

  let positionX = 0, positionY = 0;
  let coordXprocent = 0, coordYprocent = 0;

  function setMouseParalaxStyle() {
      const distX = coordXprocent - positionX;
      const distY = coordYprocent - positionY;

      positionX = positionX + (distX * paralaxSpeed);
      positionY = positionY + (distY * paralaxSpeed);

      ship.style.cssText = `transform: translate(${positionX / forShip}%, ${positionY / forShip}%);`;
      island.style.cssText = `transform: translate(${positionX / forIsland}%, ${positionY / forIsland}%);`;
      zub.style.cssText = `transform: translate(${positionX / forZub}%, ${positionY / forZub}%);`;
      zub2.style.cssText = `transform: translate(${positionX / forZub2}%, ${positionY / forZub2}%);`;
      ball.style.cssText = `transform: translate(${positionX / forBall}%, ${positionY / forBall}%);`;
      aboutImg.style.cssText = `transform: translate(${positionX / forAboutImg}%, ${positionY / forAboutImg}%);`;
      servicesZub.style.cssText = `transform: translate(${positionX / forServicesZub}%, ${positionY / forServicesZub}%);`;
      robot.style.cssText = `transform: translate(${positionX / forRobot}%, ${positionY / forRobot}%);`;
      footerShip.style.cssText = `transform: translate(${positionX / forFooterShip}%, ${positionY / forFooterShip}%);`;

      requestAnimationFrame(setMouseParalaxStyle);
  }

  setMouseParalaxStyle();

  paralax.addEventListener('mousemove', function (e) {
      
      const paralaxWidth = paralax.offsetWidth;
      const paralaxHeight = paralax.offsetHeight;

      const coordX = e.pageX - paralaxWidth / 2;
      const coordY = e.pageY - paralaxHeight / 2;

      coordXprocent = coordX / paralaxWidth * 500;
      coordYprocent = coordY / paralaxHeight * 500;
  })
}

